import { Grid, Container, Typography, IconButton, Button, Box } from "@mui/material";
import Logo from "./logoNav.png";
import axios from "axios";
import MenuButton from "../Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "../Select/Select";
import './Header.css'
import { Fragment, useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { header_section, footer_section } from "../../utils/types";
import { REACT_APP_api_base_url, DEFAULT_LANGUAGE } from "../../utils/url_config";
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

const Header = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedLang = queryParams.get('lang') || 'en';
   const [languageState, setLanguageState] = useState(() => localStorage.getItem('language') || selectedLang);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [open, setOpen] = useState(false);
  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };

  const [headerState, setHeaderState] = useState(false);
  const [HeaderMenusData, setHeaderMenusData] = useState<header_section>();
  const [languageSwitcherData, setLanguageSwitcherData] = useState<footer_section>();
  const [clickedValue, setClickedValue] = useState('');

  const showHeader = () => {
    setHeaderState(!headerState)
  }
  const navigate = useNavigate();

  const handleLanguageChange = (newLang) => {
    setLanguageState(newLang);
    localStorage.setItem('language', newLang);
    window.dispatchEvent(new Event('storage'));
  };

  const handleButtonClick = (value) => {
    setClickedValue(value);
  };

  useEffect(() => {
    if (width <= 768) {
      setIsMobile(true)
    }
  }, [width]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      setLanguageState(localStorage.getItem('language') || 'en')
    });
  }, []);

  useEffect(() => {
    const fetchHeaderData = async () => {
      try {
        const result = await axios.get(REACT_APP_api_base_url +
          "/api/headers?populate=deep&locale=" +
          localStorage.getItem("language")
        );
        setHeaderMenusData(result.data.data[0].attributes);
      } catch (error) {
        console.error("Error fetching learn about data: ", error);
        try {
          const result = await axios.get(REACT_APP_api_base_url +
            "/api/headers?populate=deep&locale=" +
            DEFAULT_LANGUAGE
          );
          setHeaderMenusData(result.data.data[0].attributes);
        } catch (error) {
          console.error(
            "Error fetching learn about data with default locale: ",
            error
          );
        }
      }

      try {
        const result_footer = await axios.get(REACT_APP_api_base_url +
          "/api/footers?populate=deep&locale=" +
          localStorage.getItem("language")
        );
        setLanguageSwitcherData(result_footer.data.data[0].attributes);
      } catch (error) {
        console.error("Error fetching learn about data: ", error);
        try {
          const result_footer = await axios.get(REACT_APP_api_base_url +
            "/api/footers?populate=deep&locale=" +
            DEFAULT_LANGUAGE
          );
          setLanguageSwitcherData(result_footer.data.data[0].attributes);
        } catch (error) {
          console.error(
            "Error fetching learn about data with default locale: ",
            error
          );
        }
      }

    };
    fetchHeaderData()
  }, [languageState]);

  useEffect(() => {
    if (HeaderMenusData) {
      setHeaderState(true)
    }
  }, [HeaderMenusData]);

  useEffect(() => {
    window.addEventListener("storage", () => {
      setLanguageState(localStorage.getItem("language") || "en");
    });
  }, []);

  const lang = languageState;
  const labels = {
    'en': {
      menuLabel: "Menu",
    },
    'es': {
      menuLabel: "Menú",
    },
    'bah': {
      menuLabel: "Meni",
    },
    'pt-BR': {
      menuLabel: "Menu",
    },
    'vi': {
      menuLabel: "Thực đơn",
    },
    'zh': {
      menuLabel: "菜单",
    },
    'ar': {
      menuLabel: "قائمة",
    },
  };

  const selectedMenuLabels = labels[lang] || labels["en"];

  return (
    <Fragment>
      {!isMobile ?
        <Grid container sx={{ py: 3, boxShadow: '1', background: '#ffffff' }} position="relative" zIndex="1" className="header">
          <Container maxWidth="xl">
            <Grid container spacing={2} sx={{ alignItems: 'center' }} className="responsive">
              <Grid item className="nav-logo">
                {HeaderMenusData ?
                  <Link href="/Home"> <img className="nav" src={HeaderMenusData?.Header_Logo.data[0].attributes.url} alt="Logo" /></Link>
                  : null
                }
              </Grid>
              <Grid item className="nav-list" sx={{ mx: 'auto' }}>

                {headerState && HeaderMenusData ? (<>
                  {HeaderMenusData?.Header_Link.map(card => {
                    return (
                      <MenuButton
                        className="nav-button"
                        routeName={card.Menu_Link}
                        onClick={() => navigate(`/${card.Menu_Link}`)}
                        data-testid="header-${card.Menu_Link}"
                      >
                        {card.Link_Name}
                      </MenuButton>
                    )
                  }
                  )}
                </>
                ) : (null)}
              </Grid>
              <Grid item className="language-selector">
                <Select />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        :
        <AppBar position="static" sx={{ py: 3, px: 3, boxShadow: '1', background: '#ffffff' }}>
          <Grid container sx={{ alignItems: 'center' }}>

            <Grid className="nav-logo">
              {HeaderMenusData ?
                <Link href="/Home"> <img className="nav" src={HeaderMenusData?.HeaderMobile_Logo.data.attributes.url} alt="Logo" /></Link>
                : null
              }
            </Grid>

            <Grid ml="auto">
              <Button className="menu-btn" onClick={toggleDrawer(true)}>{selectedMenuLabels.menuLabel}<ExpandMoreIcon /></Button>
            </Grid>

          </Grid>

          <Drawer
            anchor="left"
            open={open}
            onClose={toggleDrawer(false)}
            className="toggle-drawer"
          >
            <div
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >

              <CloseIcon className="cross-icon" onClick={toggleDrawer(false)} />

              <Grid item className="nav-list" sx={{ mx: 'auto' }}>

                {headerState && HeaderMenusData ? (<>
                  {HeaderMenusData?.Header_Link.map(card => {
                    return (
                      <Box className="menu-list">
                        <MenuButton
                          className="nav-button"
                          routeName={card.Menu_Link}
                          onClick={() => {
                            handleButtonClick(card.Link_Name);
                            navigate(`/${card.Menu_Link}`);
                          }}
                          data-testid="header-${card.Menu_Link}"
                        >
                          {card.Link_Name}
                        </MenuButton>
                      </Box>
                    )
                  }
                  )}
                </>
                ) : (null)}
              </Grid>
              <Grid item className="language-selector">
                <Typography textTransform="uppercase" color="#666666" mb='0.4rem'>Languages</Typography>
                <MenuList className="language-menu">
                {languageSwitcherData?.Footer_Language?.Languages.map((item, index) => (
                  [
                    <MenuItem sx={{ mb: 0, p: 0, minHeight: 'auto' }}><Link href="#" onClick={() => handleLanguageChange(item.Lang_Code)}>{item.Language}</Link></MenuItem>
                    
                  ]))}
              </MenuList>
            </Grid>
           

          </div>
        </Drawer>
        </AppBar>
      }
    </Fragment >
  );
};

export default Header;

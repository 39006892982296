import React from 'react';

const TextWithBold = ({ content }) => {
  const boldText = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Use `dangerouslySetInnerHTML` to render HTML
  return <div dangerouslySetInnerHTML={{ __html: boldText }} />;
};

export default TextWithBold;

import { Grid, Typography, Link } from '@mui/material'
import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { REACT_APP_api_base_url, DEFAULT_LANGUAGE } from "../../utils/url_config";
import { global_API } from '../../utils/types';
export const Sources = ({ number, text1, text2, text3 }: any) => {
    const [languageState, setLanguageState] = useState(localStorage.getItem("language")|| "en");
    const [globalData, setGlobalData] = useState<global_API>();
    
    useEffect(() => {
      window.addEventListener("storage", () => {
        setLanguageState(localStorage.getItem("language") || "en");
      });
    }, []);

    useEffect(() => {
      const fetchglobalData = async () => {
        try {
          const result = await axios.get( REACT_APP_api_base_url +
            "/api/global-translation-api?populate=deep&locale=" +
              localStorage.getItem("language")
          );
          setGlobalData(result.data);
        } catch (error) {
          console.error("Error fetching learn about data: ", error);
          try {
            const result = await axios.get( REACT_APP_api_base_url +
              "/api/global-translation-api?populate=deep&locale=" +
                DEFAULT_LANGUAGE
            );
            setGlobalData(result.data);
          } catch (error) {
            console.error(
              "Error fetching learn about data with default locale: ",
              error
            );
          }
        }
      };
  
      fetchglobalData();
    }, [languageState]);

    const lang = languageState;
    
  const addSourceLinkToText = (text) => {
    const paragraphs = text.split('</p>');
    if (paragraphs.length > 1) {
      paragraphs[paragraphs.length - 2] += ` <a href="${text2}">${text3}</a></p>`;
      return paragraphs.join('');
    }
    return text;
  };

  const modifiedText1 = addSourceLinkToText(text1);

  return (
    <Grid container item flexWrap="nowrap" mb="2rem">
      <Grid
        item
        borderRadius="50%"
        bgcolor="#DFF0D8"
        sx={{ width: '50px', height: '50px', minWidth: '50px' }}
        textAlign="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography
          variant="h6"
          fontWeight="500"
          fontSize="24px"
          lineHeight="26px"
        >
          {number}
        </Typography>
      </Grid>
      <Grid item ml="2rem">
        <div dangerouslySetInnerHTML={{ __html: modifiedText1 }} className="source-content"></div>
      </Grid>
    </Grid>
  )
}

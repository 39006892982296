import React from 'react'

export default function ReplaceAnchor({ content }) {
    const createMarkup = (content) => {
      return { __html: content };
    };
  
    return (
      <div className="link-green" dangerouslySetInnerHTML={createMarkup(content)} />
    );
}

import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import { REACT_APP_api_base_url, DEFAULT_LANGUAGE } from "../../utils/url_config";
import { footer_section } from "../../utils/types";

export default function BasicSelect() {
  const location = useLocation();
  const navigate = useNavigate();

  const getInitialLang = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const initialLang = queryParams.get('lang') || localStorage.getItem('language') || 'en';
    return initialLang;
  };

  const [lang, setLang] = useState(getInitialLang());
  const [languageSwitcherData, setLanguageSwitcherData] = useState<footer_section>();
  const [isRedirecting, setIsRedirecting] = useState(false); // Flag for redirection

  useEffect(() => {
    if (localStorage.getItem("language") !== lang) {
      localStorage.setItem("language", lang);
      window.dispatchEvent(new Event('storage'));
    }
  }, [lang]);

  useEffect(() => {
    const handleStorageChange = () => {
      const newLang = localStorage.getItem('language');
      if (newLang && newLang !== lang) {
        setLang(newLang);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [lang]);

  const handleChange = (event: SelectChangeEvent) => {
    const newLang = event.target.value as string;
    setLang(newLang);
    localStorage.setItem('language', newLang);
    localStorage.removeItem('QuizQuestions');
    window.dispatchEvent(new Event('storage'));

    if (location.pathname === '/MyStuff' || location.pathname.includes('/question/') || location.pathname === '/QuizResult') {
      navigate('/MyValues');
    }

    if (newLang === 'ar' && !isRedirecting) {
      setIsRedirecting(true);
      window.open('http://arabic.inductiondecisionaid.org/', '_blank');
      setLang('en'); 
      localStorage.setItem('language', 'en');
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('lang', 'en'); 
      window.history.pushState({}, '', newUrl);
      setTimeout(() => setIsRedirecting(false), 1000);

    } else {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('lang', newLang);
      window.history.pushState({}, '', newUrl);
    }
  };

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const resultFooter = await axios.get(`${REACT_APP_api_base_url}/api/footers?populate=deep&locale=${localStorage.getItem("language")}`);
        setLanguageSwitcherData(resultFooter.data.data[0].attributes);
      } catch (error) {
        console.error("Error fetching footer data: ", error);
        try {
          const resultFooter = await axios.get(`${REACT_APP_api_base_url}/api/footers?populate=deep&locale=${DEFAULT_LANGUAGE}`);
          setLanguageSwitcherData(resultFooter.data.data[0].attributes);
        } catch (error) {
          console.error("Error fetching footer data with default locale: ", error);
        }
      }
    };

    fetchFooterData();
  }, []);

  useEffect(() => {
    if (lang === 'ar' && !isRedirecting) {
      setIsRedirecting(true);
      window.open('http://arabic.inductiondecisionaid.org/', '_blank');
      setLang('en'); 
      localStorage.setItem('language', 'en');
      setTimeout(() => setIsRedirecting(false), 1000); 
    }
  }, [lang, isRedirecting]);

  const desiredOrder = ['en', 'es', 'pt-BR', 'bah', 'zh', 'vi', 'ar'];
  const sortedLanguages = languageSwitcherData?.Footer_Language?.Languages.sort((a, b) => {
    return desiredOrder.indexOf(a.Lang_Code) - desiredOrder.indexOf(b.Lang_Code);
  });

  return (
    <Box sx={{ minWidth: 120 }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={lang}
        onChange={handleChange}
      >
        {sortedLanguages?.map((item, index) => (
          <MenuItem key={index} value={item.Lang_Code}>
            {item.Language.split(' (')[0]}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { VscArrowRight } from 'react-icons/vsc'
import './pageStyle/Welcome.css'
import FooterBar from '../components/Footer/FooterBar'
import Layout from '../components/Layout'
import Container from '@mui/material/Container';
import {
  welocome_page,
} from '../utils/types'
import { REACT_APP_api_base_url, DEFAULT_LANGUAGE } from '../utils/url_config'
import axios from 'axios'

const Welcome = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [DetailsWelcomeData, setWelcomeData] = useState<welocome_page>();
  const [languageState, setLanguageState] = useState(localStorage.getItem('language') || 'en');

  const navigateHome = (language: string) => {
    if (language === 'ar') {
      window.open('http://arabic.inductiondecisionaid.org/', '_blank');
    } else {
      navigate(`/Home?lang=${language}`);
    }
  };

  useEffect(() => {
    window.addEventListener('storage', () => {
      setLanguageState(localStorage.getItem('language') || 'en');
    });
  }, []);

  useEffect(() => {
    if (width <= 768) {
      setIsMobile(true);
    }
  }, [width]);

  useEffect(() => {
    const fetchDetailsWelcomeData = async () => {
      try {
        const result = await axios.get(
          REACT_APP_api_base_url + '/api/welcome-page?populate=deep&locale=' + localStorage.getItem('language')
        );
        setWelcomeData(result.data);
      } catch (error) {
        console.error('Error fetching learn about data: ', error);
        try {
          const result = await axios.get(
            REACT_APP_api_base_url + '/api/welcome-page?populate=deep&locale=' + DEFAULT_LANGUAGE
          );
          setWelcomeData(result.data);
        } catch (error) {
          console.error('Error fetching learn about data with default locale: ', error);
        }
      }
    };
    fetchDetailsWelcomeData();
  }, [languageState]);

  const Englangdata = DetailsWelcomeData?.data?.attributes;
  const Otherlangdata = DetailsWelcomeData?.data?.attributes?.localizations.data;

  // Desired order of language codes
  const desiredOrder = ['en', 'es', 'pt-BR', 'bah', 'zh', 'vi', 'ar'];

  // Sort the languages according to the desired order
  const sortedLanguages = Otherlangdata?.sort((a, b) => {
    return desiredOrder.indexOf(a.attributes.locale) - desiredOrder.indexOf(b.attributes.locale);
  });

  return (
    <div>
      <div className="welcome-section">
        <Container maxWidth="sm">
          <div className="allWelcome">
            <p style={{ marginBottom: '1.5rem' }}>Pick one to continue</p>

            <button className="Welcome" onClick={() => navigateHome(`${Englangdata?.locale}`)}>
              <h1>
                {Englangdata?.title} <VscArrowRight />
              </h1>
            </button>
            <p style={{ marginBottom: '1.5rem' }}>{Englangdata?.welcome_message}</p>

            {sortedLanguages?.map((item, index) => (
              <React.Fragment key={index}>
                <button className="Welcome" onClick={() => navigateHome(`${item.attributes?.locale}`)}>
                  <h1>
                    {item.attributes?.title} <VscArrowRight />
                  </h1>
                </button>
                <p style={{ marginBottom: '1.5rem' }}>{item.attributes?.welcome_message}</p>
              </React.Fragment>
            ))}

            <div className="skip">
              <button className="skip" onClick={() => navigateHome('en')}>
                Skip
              </button>
            </div>
          </div>
        </Container>
      </div>
      {!isMobile && (
        <div className="footer">
          <FooterBar />
        </div>
      )}
    </div>
  );
};

export default Welcome;
